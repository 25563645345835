<template>
    <!-- Component: organisms/agency-info-line -->
    <general-container :customClass="'agency-info-line'">
        <div class="card card__info">
            <div class="card-tools">
                <bookmark-note-icons
                    :agency="agencyObject"
                />
            </div>
            <div class="content card-content">
                <div class="columns reverse-columns">
                    <div class="column is-two-thirds agency-info-line__data pt-0">
                        <h2 class="title">
                            <span v-if="name == 'Administered Funds' || name == 'Legislative Branch'">{{ name }}<span v-if="acronym">({{ acronym }})</span></span>
                            <a v-else :href="url ? url : ''">{{ name }} <span v-if="acronym">({{ acronym }})</span></a>
                        </h2>

                        <div class="columns">

    <!--
                            <div class="column is-half">
    -->
                                <div class="column" v-if="agencyHead">
                                    <p class="card__info__label">Agency Head</p>
                                    <p class="card__info__data">
                                        <tag-contact :tag-text="getName(agencyHead.ContactRef)" :tag-url="agencyHead.ContactRef.url"/>
                                    </p>
                                </div>
    <!--
                                <div class="column" v-if="budgetAppropriations">
                                    <p class="card__info__label">Budget Appropriations</p>
                                    <p class="card__info__data">${{ numberWithCommas(budgetAppropriations) }}</p>
                                </div>

                            </div>

                            <div class="column is-half">
    -->
                                <div class="column" v-if="totalEmployees">
                                    <p class="card__info__label">Total Employees</p>
                                    <p class="card__info__data">{{ totalEmployees }}</p>
                                </div>
    <!--
                                <div class="column" v-if="budgetDisbursements">
                                    <p class="card__info__label">Budget Disbursements</p>
                                    <p class="card__info__data">${{ numberWithCommas(budgetDisbursements) }} <span class="card__info__label">({{ budgetDisbursementsPercentage }}%)</span></p>
                                </div>

                            </div>
    -->
                        </div>

                        <div class="columns">
                            <template v-if="budgetAppropriations">
                                <div class="column">
                                    <div>
                                        <p class="card__info__label budget-heading">
                                            <Tooltip :tooltipText="definitions && definitions['Operating Budget'] && definitions['Operating Budget'].definition ? definitions['Operating Budget'].definition : 'Loading...'">
                                                <span class='has-tooltip'>
                                                    Operating Budget
                                                </span>
                                            </Tooltip>
                                        </p>
                                        <p class="total-budget">${{ priceFormat(budgetAppropriations) }}</p>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="column is-one-third agency-info-line__image" v-if="logo">
                        <div class="media">
                            <figure class="image">
                                <img :src="logo.Image.url" :alt="logo.Image.alt"/>
                            </figure>
                        </div>
                    </div>

                </div>

                <div v-if="isNaN(getPC()) === false">
                    <div class="barWrapper">
                        <div class="miniBar">
                            <div :class="'miniBarProgress spent '" :style="'left: 0; width: ' + getPC() + '%; background-color: gray;'" v-if="getPC() > 0"><span>{{ getPC() }}%</span></div>
                            <div :class="'miniBarProgress remains ' + checkAmount()" :style="'left: ' + getPC() + '%; width: ' + (100 - getPC()) + '%; background-color: red;'"><span>{{ 100 - getPC() + '%' }}</span></div>
                        </div>
                    </div>

                    <nav class="level progress-level">
                        <div class="level-item has-text-right">
                            <div>
                                <p class="card__info__label mb-5">Amount Spent</p>
                                <p class="title">${{ priceFormat(budgetDisbursements) + ' (' + getPC() + '%)' }}</p>
                            </div>
                        </div>
                        <div class="level-item has-text-centered slash-container">
                            <div>
                                <span class="slash">/</span>
                            </div>
                        </div>
                        <div class="level-item has-text-left">
                            <div>
                                <p class="card__info__label mb-5">Remaining Budget</p>
                                <p class="title">${{ priceFormat(budgetAppropriations - budgetDisbursements) + ' (' + (100 - getPC()) + '%)' }}</p>
                            </div>
                        </div>
                    </nav>
                </div>

            </div>
        </div>

    </general-container>
    <!-- End Component: organisms/agency-info-line -->
</template>

<script>
    import { ref, computed } from 'vue';
    import { useStore } from "vuex";
    import GeneralContainer from "./general-container";
    import BookmarkNoteIcons from "./bookmark-note-icons"
    import TagContact from "./tag--contact";
    import { priceFormat } from "@/helpers/filter";
    import Tooltip from "./tooltip";

    export default {
        name: 'AgencyInformationLine',
        components: {
            GeneralContainer,
            TagContact,
            Tooltip,
            BookmarkNoteIcons
        },
        props: {
            agencyObject: Object,
            name: String,
            type: String,
            url: String,
            logo: Object,
            totalEmployees: String,
            budgetAppropriations: Number,
            budgetDisbursements: Number,
            agencyHead: Object,
            acronym: String,
        },
        computed: {
            budgetDisbursementsPercentage() {
                if (!this.budgetDisbursements || !this.budgetAppropriations) {
                    return 0;
                }
                return Math.round(this.budgetDisbursements/this.budgetAppropriations * 100);
            }
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
        },
        methods: {
            getPC() {
                let value = Math.round(this.budgetDisbursements / this.budgetAppropriations * 100)

                // we need to prevent these from ever going over 100% https://app.clickup.com/t/2daaa7j
                if (value > 100) {
                    return 100;
                }

                if (value === 0) {
                    if (this.budgetDisbursements > 0){
                        value = 1
                    }
                }
                return value
            },
            checkAmount() {
                let amount = Math.round(this.budgetDisbursements / this.budgetAppropriations * 100);
                if (amount == 100) {
                    return ' full';
                } else if (amount == 0) {
                    return ' empty';
                }
            },
            getName(contact) {
                return contact.firstName + ' ' + contact.lastName;
            },
            numberWithCommas(x) {
                if (!x) {
                    return x;
                }
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        setup(props) {
            const store = useStore();
            const definitions = computed(() => store.getters.definitions);

            return {
                priceFormat,
                definitions
            };
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    // This mimics 'push' and 'pull' from Bootstrap so we can swap the logo to the top when collapsed down on mobile.
    @media(max-width: 767px) {
        .reverse-columns {
            flex-direction: column-reverse;
            display: flex;
        }
    }

    .media {
        display: block;
        .image {
            float: right;
            img {
                height: auto;
                width: auto;
                max-width: 150px;
                max-height: 150px;
            }
        }
    }

    .title {
        margin-bottom: 30px !important;
        a {
            &:active, &:hover, &:focus {
                color: $blue--dark;
            }
        }
    }

    .budget-heading {
        margin-bottom: 0px !important;
    }

    .total-budget {
        font-size: 16px;
        font-weight: 600;
    }

    .content {
        margin-top: 10px;
    }

    .wsp {
        white-space: pre;
    }

    .agency-info-line {
        border-radius: 0;
        margin-bottom: 15px;
        background-color: $white;
        border-radius: .25rem;
        padding: 15px;
        box-shadow: $box-shadow--light;
        padding: 0px;
    }

    .agency-info-line__data .column, .agency-info-line__image .column {
        padding-top: 0;
        padding-bottom: 0;
    }

    .agency-info-line__image {
        @media screen and (max-width: 768px) {
            display: flow-root;
            .media .image {
                float: inherit;
                margin-bottom: 20px;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .agency-info-line__image, .agency-info-line__data {
            width: 100%;
        }
    }

    .slash {
        font-size: 42px;
    }

    .icon-container {
        position: relative;
        float: right;
        margin: 0 30px 0 0
    }

    .level-item .title {
        font-size: 1rem;
    }

    @media screen and (max-width: 768px) {
        .slash-container {
            display: none;
        }
        .level-item .title {
            margin-bottom: 10px !important;
        }
    }

    .card__info__label.mb-5 {
        margin-bottom: 5px !important;
    }
</style>

