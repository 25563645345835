<template>
    <!-- Page: pages/browse-agencies -->

    <br/>

    <breadcrumbs :items="path"/>

    <div class="container">
        <alert/>
    </div>

    <hr/>

    <div class="container dfw mobile-contain">
        <h1>Browse
            <Tooltip :tooltipText="definitions && definitions.Agency && definitions.Agency.definition ? definitions.Agency.definition : 'Loading...'">
                <span :class="definitions && definitions.Agency && definitions.Agency.definition ? 'has-tooltip' : ''">
                    Agencies
                </span>
            </Tooltip>
        </h1>
        <userTools/>
    </div>

    <hr/>

    <div class="container mb-50">
        <general-container title="State of Florida Agency Organizational Chart" custom-class="org-chart-container">
            <org-tree-chart/>
        </general-container>
    </div>

    <div id="AgencyList" class="container">
        <general-container
            :customClass="'column'"
            :isLocked="false"
        >

            <div class="browse-agencies-filter-wrapper">
                <search-block :type="'filter'" :title="'Filter Agencies'" :placeholder="'Start typing to narrow results.'" v-on:search-keyup="keyup"/>
            </div>

            <preloader v-if="!agencies || agencies.length === 0"/>

            <div class="filter-container">
                <div class="filter">
                    <span class="filter-labels">Domain:</span>
                    <custom-select
                        :uniqueId="uniqueId"
                        :items="domains"
                        name="domain"
                        placeholder="Select a domain..."
                        v-model:value="domain"
                        :forceWidth="475"
                    ></custom-select>
                </div>

                <div class="filter">
                    <span class="filter-labels">Sort:</span>
                    <custom-select
                        :uniqueId="uniqueId"
                        :items="sortOptions"
                        name="sort"
                        placeholder="Select a sort..."
                        v-model:value="sort"
                        :forceWidth="320"
                    ></custom-select>
                </div>
            </div>

            <p v-if="!(!agencies || agencies.length === 0) && filteredAgencies().length === 0">There are no results. Please refine your search.</p>
            <agency-information-line
                v-for="agency in filteredAgencies()"
                :agencyObject = "agency"
                :name="agency.name"
                :type="agency.type"
                :logo="agency.logo"
                :url="agency.url"
                :acronym="agency.acronym"
                :totalEmployees="agency.totalEmployees"
                :budgetAppropriations="agency.budgetAppropriations"
                :budgetDisbursements="agency.budgetDisbursements"
                :key="agency.contentId"
                :agencyHead="agency.agencyHead ? agency.agencyHead : null"
            />

        </general-container>

    </div>
    <!-- End Page: pages/browse-agencies -->
</template>

<script>
    import RequestHandler from "@/handler/RequestHandler";
    import AgencyInformationLine from "./agency-info-line";
    import Alert from "@/alivue/components/alert";
    import Breadcrumbs from "@/alivue/components/breadcrumbs";
    import GeneralContainer from "./general-container";
    import UserTools from "@/alivue/components/user-tools";
    import SearchBlock from "./search-block";
    import { computed } from "vue";
    import { useStore } from "vuex";
    import Preloader from "./preloader";
    import Tooltip from "./tooltip";
    import OrgTreeChart from "./org-tree-chart";
    import CustomSelect from "./custom-select";

    export default {
        components: {
            Preloader,
            AgencyInformationLine,
            Alert,
            Breadcrumbs,
            GeneralContainer,
            UserTools,
            SearchBlock,
            Tooltip,
            OrgTreeChart,
            CustomSelect,
        },
        data() {
            return {
                search: '',
                domain: -1,
                sort: 'alpha desc',
                sortOptions: [
                    {
                        name: 'Alphabetical (A-Z)',
                        value: 'alpha desc',
                    },
                    {
                        name: 'Alphabetical (Z-A)',
                        value: 'alpha asc',
                    },
                    {
                        name: 'Budget (high to low)',
                        value: 'budget desc',
                    },
                    {
                        name: 'Budget (low to high)',
                        value: 'budget asc',
                    },
                    {
                        name: 'Budget Remaining (high to low)',
                        value: 'budget_remaining desc',
                    },
                    {
                        name: 'Budget Spent (high to low)',
                        value: 'budget_spent desc',
                    },
                    {
                        name: 'Number of Employees (high to low)',
                        value: 'employees desc',
                    },
                    {
                        name: 'Number of Employees (low to high)',
                        value: 'employees asc',
                    },
                ],
            };
        },
        methods: {
            keyup(s) {
                this.search = s;
            },
            filteredAgencies() {
                if (!this.agencies) {
                    return [];
                }
                let filtered = this.agencies.filter(agency => {
                    if (this.domain && this.domain !== -1) {
                        if (!agency.domain || this.domain !== agency.domain) {
                            return false;
                        }
                    }
                    return (agency.name.toLowerCase().includes(this.search.toLowerCase()) || agency.acronym.toLowerCase().includes(this.search.toLowerCase()))
                });
                function compareAlphaDesc(a, b) {
                    if (a.name.toLowerCase() === 'administered funds') {
                        return 1;
                    }
                    if (a.name.toLowerCase() === 'legislative branch') {
                        return 1;
                    }
                    if (b.name.toLowerCase() === 'administered funds') {
                        return -1;
                    }
                    if (b.name.toLowerCase() === 'legislative branch') {
                        return -1;
                    }
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }
                function compareAlphaAsc(a, b) {
                    if (a.name.toLowerCase() === 'administered funds') {
                        return 1;
                    }
                    if (a.name.toLowerCase() === 'legislative branch') {
                        return 1;
                    }
                    if (b.name.toLowerCase() === 'administered funds') {
                        return -1;
                    }
                    if (b.name.toLowerCase() === 'legislative branch') {
                        return -1;
                    }
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return 1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                }
                function compareBudgetDesc(a, b) {
                    if (a.budgetAppropriations === b.budgetAppropriations) {
                        return 0;
                    }
                    return a.budgetAppropriations < b.budgetAppropriations ? 1 : -1;
                }
                function compareBudgetAsc(a, b) {
                    if (a.budgetAppropriations === b.budgetAppropriations) {
                        return 0;
                    }
                    return a.budgetAppropriations < b.budgetAppropriations ? -1 : 1;
                }
                function compareBudgetRemainingDesc(a, b) {
                    let aRemaining = a.budgetAppropriations - a.budgetDisbursements;
                    let bRemaining = b.budgetAppropriations - b.budgetDisbursements;
                    if (aRemaining === bRemaining) {
                        return 0;
                    }
                    return aRemaining < bRemaining ? 1 : -1;
                }
                function compareBudgetSpentDesc(a, b) {
                    if (a.budgetDisbursements === b.budgetDisbursements) {
                        return 0;
                    }
                    return a.budgetDisbursements < b.budgetDisbursements ? 1 : -1;
                }
                function compareBudgetSpentAsc(a, b) {
                    if (a.budgetDisbursements === b.budgetDisbursements) {
                        return 0;
                    }
                    return a.budgetDisbursements < b.budgetDisbursements ? -1 : 1;
                }
                function compareEmployeesDesc(a, b) {
                    let aEmployees = Number.parseInt(a.totalEmployees.replace(',', '').replace('+', ''));
                    if (isNaN(aEmployees)) {
                        aEmployees = 0;
                    }
                    let bEmployees = Number.parseInt(b.totalEmployees.replace(',', '').replace('+', ''));
                    if (isNaN(bEmployees)) {
                        bEmployees = 0;
                    }
                    if (aEmployees === bEmployees) {
                        return 0;
                    }
                    return aEmployees < bEmployees ? 1 : -1;
                }
                function compareEmployeesAsc(a, b) {
                    let aEmployees = Number.parseInt(a.totalEmployees.replace(',', '').replace('+', ''));
                    if (isNaN(aEmployees)) {
                        aEmployees = 0;
                    }
                    let bEmployees = Number.parseInt(b.totalEmployees.replace(',', '').replace('+', ''));
                    if (isNaN(bEmployees)) {
                        bEmployees = 0;
                    }
                    if (aEmployees === bEmployees) {
                        return 0;
                    }
                    return aEmployees < bEmployees ? -1 : 1;
                }
                let compareFunc = null;
                switch  (this.sort) {
                    case 'alpha desc':
                        compareFunc = compareAlphaDesc;
                        break;
                    case 'alpha asc':
                        compareFunc = compareAlphaAsc;
                        break;
                    case 'budget asc':
                        compareFunc = compareBudgetAsc;
                        break;
                    case 'budget desc':
                        compareFunc = compareBudgetDesc;
                        break;
                    case 'budget_remaining desc':
                        compareFunc = compareBudgetRemainingDesc;
                        break;
                    case 'budget_spent asc':
                        compareFunc = compareBudgetSpentAsc;
                        break;
                    case 'budget_spent desc':
                        compareFunc = compareBudgetSpentDesc;
                        break;
                    case 'employees asc':
                        compareFunc = compareEmployeesAsc;
                        break;
                    case 'employees desc':
                        compareFunc = compareEmployeesDesc;
                        break;
                }
                if (compareFunc) {
                    filtered.sort(compareFunc);
                }
                return filtered;
            },
        },
        computed: {
            agencies: function () {
                return this.$store.getters.agencies;
            },
            domains: function() {
                let domains = [
                    {
                        value: -1,
                        name: 'All domains',
                    }
                ];
                if (!this.agencies) {
                    return domains;
                }
                let seenDomainValues = [];
                let domainOptions = [];
                for (let i = 0; i < this.agencies.length; i++) {
                    if (!this.agencies[i].domain) {
                        continue;
                    }
                    if (seenDomainValues.indexOf(this.agencies[i].domain) !== -1) {
                        continue;
                    }
                    seenDomainValues.push(this.agencies[i].domain);
                    domainOptions.push({
                        name: this.agencies[i].domain,
                        value: this.agencies[i].domain,
                    });
                }
                function compare(a, b) {
                    if (a.name === b.name) {
                        return 0;
                    }
                    return a.name < b.name ? -1 : 1;
                }
                domainOptions.sort(compare);
                return domains.concat(domainOptions);
            }
        },
        mounted() {

        },
        props: ['uniqueId'],
        setup(props) {
            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);
            const definitions = computed(() => store.getters.definitions);

            return {
                path: [{title: 'Browse Agencies'}],
                showSearch,
                definitions,
            };
        },
    }
</script>

<style lang="scss" scoped>
    .browse-agencies-filter-wrapper {
        max-width: 50%;
        margin: auto;
        @media screen and (max-width: 768px) {
            width: 90%;
            max-width: 90%;
        }
        .search-block {
            margin-bottom: 30px;
        }
    }
    .mobile-contain {
        h1 {
            float: left;
        }
        @media screen and (max-width: 1021px) {
            margin-left: 15px;
            margin-right: 15px;
            h1 {
                text-align: center;
                float: none;
            }
        }
    }
    .filter-container {
        width: 100%;
        display: block;
        text-align: center;
        margin-bottom: 15px;
        span {
            font-weight: bold;
            @media screen and (min-width: 601px) {
                position: relative;
                top: 18px;
                margin-right: 10px;
                &:not(:first-of-type) {
                    margin-left: 10px;
                }
            }
        }
    }
    .mb-50 {
        margin-bottom: 50px;
    }
    .filter {
        display: inline-block;
        margin-bottom: 15px;
        &:first-of-type {
            margin-right: 15px;
        }
        @media screen and (max-width: 768px) {
            max-width: 100%;
        }
    }
    @media screen and (max-width: 600px) {
        .dropdown-trigger button.button {
            width: 100% !important;
        }
        .filter-labels {
            width: 100% !important;
            display: block;
        }
    }
</style>
